/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalIndraInfo } from "App";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// importing images here
import PDF from "assets/ActivityAssests/svg/pdf.svg";
import XLS from "assets/ActivityAssests/svg/xls.svg";
import DOCS from "assets/ActivityAssests/svg/docs.svg";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import {  getData, 
          postData, 
          uploadMultipleFile,
        } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
import { useTranslation, Trans } from "react-i18next";

// import inages
import defaultUser from "assets/ActivityAssests/images/default-user.png";
import AddTagModalRightPanel from "components/ActivityComponents/ActivityModal/AddTagModalRightPanel";

const ActivityRightpanel = (props) => {
  // let token = "";
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // saving loggedin user's Id
  const loggedInUserID = localStorage.getItem("userID");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type here..."),
  };
  /*----- end of CKEditor classic editor part----- */

  const postId = props.activityPostId;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [commentMessage, setCommentMessage] = useState("");
  const [loadTopics, setLoadTopics] = useState(false);
  const [isCommentPosting, setIsCommentPosting] = useState(false);
  const [isReplyPosting, setIsReplyPosting] = useState(false);
  const [replyWarning, setReplyWarning] = useState(false);
  const [privilegeNotAvailable, setPrivilegeNotAvailable] = useState(false);

  //initial state for toggle reply box
  const [toggleReplyBox, setToggleReplyBox] = useState(null);
  const [assignClass, setAssignClass] = useState(false);

  // ---------------------------- useState to change color of the icons ---------------------------------------
  const [trophyColor, setTrophyColor] = useState("grey");
  const [isLiked, setIsLiked] = useState(false);
  // ----------------------------------------------------------------------------------------------------------

  // initial state for reply to parent message
  const [replyMessage, setReplyMessage] = useState("");

  //initial for empty message
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);

  // initial for reply box
  const [replyBoxIndex, setReplyBoxIndex] = useState(null);

  //initial for upload bigger file
  const [fileIsBig, setFileIsBig] = useState(false);
  // initial for uploading
  const [isUploading, setIsUploading] = useState(false);

  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);

  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  //spin count for spinner
  const spinCount = [1, 2, 3, 4, 5, 6, 7];

  // ref for point comment box
  const commentBoxRef = useRef(null);

  // function for load details
  const loadDetails = async (postId) => {
    const requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_DETAILS +
      `${postId}` +
      `?token=${token}`;

    try {
      const response = await getData(requestURL);

      console.log('response', response);

      if (response.status) {
        if (response.data) {
          setDetailsData(response.data);
          setPrivilegeNotAvailable(false);
        } else {
          setDetailsData(null);
          setPrivilegeNotAvailable(true);
        }
        setLoadTopics(false);
      } else {
        setDetailsData(null);
      }
    } catch (error) {
      setError(error);
      setLoadTopics(false);
    }
  };

  // function for post comment
  const answerToQuestion = async () => {
    if (commentMessage == "") {
      setShowEmptyMessage(true);
      return;
    } else {
      setIsCommentPosting(true);
      setShowEmptyMessage(false);
      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_POST_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: commentMessage,
        media: fileIds,
      };

      console.log('messageData >>>', messageData);

      setCommentMessage("");

      setMedia([]);
      setFileIds([]);

      try {

        console.log('commentUrl', commentUrl);

        const response = await postData(commentUrl, messageData);

        console.log('messageData response >>>', response);

        if (response.status) {
          loadDetails(postId);
          setIsCommentPosting(false);
        }
      } catch (error) {}
    }
  };

  // function for toggling reply box
  const showReplyBox = (index) => {
    console.log('index clicked', index);
    // if (replyBoxIndex == null) {
    //   setReplyBoxIndex(index);
    // } else {
    //   setReplyBoxIndex(null);
    // }
    if(index){
      setReplyBoxIndex(index)
    }else{
      setReplyBoxIndex(0)
    }
  };

  // function for reply comments
  const replyToAnswerHandler = async (item, reply = null) => {
    if (replyMessage == "") {
      setReplyWarning(true);
      return;
    } else {
      setReplyWarning(false);
      setIsReplyPosting(true);
      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_REPLY_TO_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: replyMessage,
        commentid: item.id,
      };

      if(reply && reply.id != null){
        messageData.replyid = reply.id
      }

      setReplyMessage("");
      setReplyBoxIndex(null);

      console.log('messageData', messageData);

      try {
        const response = await postData(commentUrl, messageData);

        console.log('response', response);

        if (response.status) {
          loadDetails(postId);
        }
        setIsReplyPosting(false);

      } catch (error) {
        console.log('error:', error.message);
      }


    }
  };

  // function for parent like
  const likeCommentHandler = async (commentId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST_COMMENT +
      `?token=${token}` +
      `&commentid=${commentId}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {}
  };

  // function for like topics
  const likePostHandler = async (postId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST +
      `?token=${token}` +
      `&postid=${postId}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {}
  };

  // function for focus to commentbox
  const commentBoxRefToggle = () => {
    commentBoxRef.current?.scrollIntoView();
  };

  // function for set champion
  const championHandler = async (item) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_SET_WINNER +
      `?token=${token}` +
      `&cid=${item.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {}
  };

  //function for delete comment
  const deleteComment = async (item) => {

    // Check if the user confirms the deletion
    const confirmDelete = window.confirm(t("Are you sure you want to delete this post?"));

    if (!confirmDelete) {
      return; // If the user cancels, do nothing
    }

    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_REMOVE_COMMENTS +
      `?token=${token}` +
      `&cid=${item.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        loadDetails(postId);
      }
    } catch (error) {}
  };


  
  

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    setFileIsBig(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 20971520) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        setIsUploading(true);
        let requestURL =
          url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);

          if (response.status == true) {
            let fileResponseId = response.data.map((data) => {
              return data.id;
            });

            if (fileIds.length == 0) {
              setFileIds(fileResponseId);
            } else {
              let fileArr = fileIds.concat(fileResponseId);
              setFileIds(fileArr);
            }
          }

          const targetFilesObject = [...targetFiles]; // store array of those files

          // check condition whether file array is empty or not
          if (media.length == 0) {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            setMedia(selectedFIles); // changing state of files array
          } else {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            // changing state of files array with previous file array values
            setMedia((media) => [...media, ...selectedFIles]);
          }
          setIsUploading(false);
        } catch (error) {
          return;
        }
      } else {
        setFileIsBig(true);
      }
    } else {
      return;
    }
  };

  //function for after modal close
  const afterModalClose = () => {
    setLoadTopics(true);
    loadDetails(postId);
  };

  // useefect for get details by post id
  useEffect(() => {
    if (postId != null) {
      setLoadTopics(true);
      loadDetails(postId);
      setReplyWarning(false);
      setReplyBoxIndex(null);
    } else {
      setDetailsData(null);
    }
  }, [postId]);

  // useeffect for load right panel
  useEffect(() => {
    if (props.loadRightPanel) {
      setLoadTopics(true);
      loadDetails(postId);
      props.disableRightPanelRefresh();
    }
  }, [props.loadRightPanel]);

  return (
    <>
      {loadTopics ? (
        <div className="glow_area">
          <div className="plan p-3 border rounded-3 placeholder-glow">
            <span className="placeholder placeholder-sm col-12 rounded-3 mb-4"></span>
            <div className="user_placeHoledr">
              <div className="default_usr">
                <img src={defaultUser} alt="default-user" />
              </div>
              <div className="default_usr_name">
                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              </div>
            </div>
            <div className="data_btnOuter">
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
            </div>
            <p className="card-text placeholder-glow">
              <span className="placeholder placeholder-sm col-12 rounded-3"></span>
              <span className="placeholder placeholder-sm col-10 rounded-3"></span>
              <span className="placeholder placeholder-sm col-9 rounded-3"></span>
              <span className="placeholder placeholder-sm col-8 rounded-3"></span>
              <span className="placeholder placeholder-sm col-7 rounded-3"></span>
              <span className="placeholder placeholder-sm col-6 rounded-3"></span>
            </p>
          </div>
        </div>
      ) : (
        <div className="activity_rightpnl">
          <div className="activity_backBtn">
            {/* --- back button for mobile version ---- */}
            <Link
              to="#"
              role="button"
              onClick={() => {
                document.body.classList.remove("activity_open");
              }}
            >
              <i className="material-icons-outlined">arrow_back</i>
              <span>{t("Back")}</span>
            </Link>
            {/* --- back button for mobile version end ---- */}
          </div>
          {/* ---- main activity details start here ----- */}
          {detailsData == null ? (
            <div className="glow_area" style={{ marginLeft: "0" }}>
              <div className="plan p-3 border rounded-3 placeholder-glow">
                <div className="activity_rightHdng">
                  {privilegeNotAvailable && (
                    <h3>{t("Not enough privilege to view this post")}</h3>
                  )}
                </div>
                <span className="placeholder placeholder-sm col-12 rounded-3 mb-4"></span>
                <div className="user_placeHoledr">
                  <div className="default_usr">
                    <img src={defaultUser} alt="default-user" />
                  </div>
                  <div className="default_usr_name">
                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  </div>
                </div>
                <div className="data_btnOuter">
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                </div>
                <p className="card-text placeholder-glow">
                  <span className="placeholder placeholder-sm col-12 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-10 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-9 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-8 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-7 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                </p>
              </div>
            </div>
          ) : (
            <div className="pnl_innr">
              <div className="activity_rightItem">
                {/* ---- post title ---- */}
                <div className="activity_rightHdng">
                  <h3>{detailsData.title}</h3>
                </div>
                {/* ---- post title end ---- */}

                {/* ---- post owner with image and date start ----- */}
                <div className="activity_userOuter">
                  <div className="activity_usrPnl">
                    {detailsData.moderator == undefined ? null : (
                      <div className="user_activty">
                        <img
                          src={
                            detailsData.moderator.thumbnail
                              ? detailsData.moderator.thumbnail
                              : defaultUser
                          }
                          alt="owner"
                        />
                      </div>
                    )}
                    {detailsData.moderator == undefined ? null : (
                      <div className="activty_usrname">
                        <h4>{detailsData.moderator.name}</h4>
                      </div>
                    )}
                  </div>
                  <div className="activity_date">
                    <i className="material-icons-round">calendar_today</i>
                    <span>{detailsData.created}</span>
                  </div>
                </div>
                {/* ---- post owner with image and date end ----- */}
                <div className="winers_likeOuter">
                  <div className="likecmnts_pnl">
                    <div className="form_share">
                      <Link
                        to="#"
                        role="button"
                        onClick={() => likePostHandler(postId)}
                      >
                        <i className="material-icons-outlined">thumb_up</i>
                        <em>{t("Likes")}</em>
                        <span>{detailsData.likes}</span>
                      </Link>
                      <Link to="#" role="button" onClick={commentBoxRefToggle}>
                        <i className="material-icons-outlined">chat</i>
                        <em>{t("Comment")}</em>
                        {detailsData.comments ? (
                          <span>{detailsData.comments.count}</span>
                        ) : null}
                      </Link>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#forum_rightpanel_tag_mdl"
                      >
                        <i className="material-icons-outlined">label</i>
                        <em>{t("Tag")}</em>
                      </Link>
                    </div>
                  </div>
                  {/* <div className="winner_user">
                <div className="activty_usrname">
                  <h5>Winner</h5>
                </div>
                <div className="user_activty">
                  <img src="images/expert2.png" alt="" />
                </div>
              </div> */}
                </div>

                {/* ----- activit files ---- */}
                {detailsData.media == null ? null : (
                  <div className="d-flex">
                    {detailsData.media.map((item, index) => {
                      return (
                        <div
                          className="post-media"
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => window.open(item, "_blank")}
                        >
                          {item.split(".").pop() == "jpg" ||
                          item.split(".").pop() == "JPG" ||
                          item.split(".").pop() == "png" ? (
                            <img
                              src={item}
                              alt="docs"
                              className="rounded"
                              height="50"
                              width="50"
                            />
                          ) : (
                            <div>
                              {item.split(".").pop() == "pdf" ? (
                                <img
                                  src={PDF}
                                  alt="docs"
                                  className="rounded"
                                  height="50"
                                  width="50"
                                />
                              ) : (
                                <div>
                                  {item.split(".").pop() == "xlsx" ||
                                  item.split(".").pop() == "xls" ? (
                                    <img
                                      src={XLS}
                                      alt="docs"
                                      className="rounded"
                                      height="50"
                                      width="50"
                                    />
                                  ) : (
                                    <div>
                                      {item.split(".").pop() == "docx" ||
                                      item.split(".").pop() == "txt" ||
                                      item.split(".").pop() == "doc" ? (
                                        <img
                                          src={DOCS}
                                          alt="docs"
                                          className="rounded"
                                          height="50"
                                          width="50"
                                        />
                                      ) : 
                                      
                                        (<div>
                                              {item.split(".").pop() == "mp4" ||
                                              item.split(".").pop() == "webm" ||
                                              item.split(".").pop() == "ogv" ? (
                                                <video controls width="150" height="150">
                                                  <source src={item} type={`video/${item.split(".").pop()}`} />
                                                  Your browser does not support the video tag.
                                                </video>
                                              ) : null}
                                            </div>)
                                      
                                      
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {/* ----- post tags start ---- */}

              {detailsData.tags.length == 0 ? null : (
                <div className="activity_rightItem post-tags">
                  <div className="activity_tagsRow">
                    <h4>{t("Tags")}</h4>

                    <div className="experts_skill activity_skill">
                      <ul>
                        {detailsData.tags.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{item.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {/* ----- post tags end ---- */}

              {/* ----- post category start ---- */}
              {detailsData.categories.length == 0 ? null : (
                <div className="activity_rightItem post-categories">
                  <div className="activity_tagsRow">
                    <h4>{t("Categories")}</h4>
                    <div className="experts_skill activity_skill">
                      <ul>
                        {detailsData.categories.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{item.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {/* ----- post category end ---- */}

              {/* ----- post descritption start ---- */}
              <div className="activity_rightItem">
                {/* <div className="activity_para">
                  <p>{detailsData.description}</p>
                </div> */}

                <div
                  dangerouslySetInnerHTML={{
                    __html: detailsData.description,
                  }}
                  className="activity_para"
                ></div>
              </div>

              {/* ----- post descritption end ---- */}
              <div ref={commentBoxRef} name="scrollRefDiv" />

              {/* ----- post reply box start ---- */}
              <div className="activity_rightItem">
                <div className="activity_commnets">
                  <h3>
                    {t("Share Your Idea")}{" "}
                    {showEmptyMessage && (
                      <span className="text-danger mx-2">
                        * {t("Please add comment")}
                      </span>
                    )}
                  </h3>{" "}

                  <div className="activity_tagsRow">
                  {/* ----- loading area for file uploading ----- */}
                  {isUploading && (
                    <div
                      className="d-flex align-items-center"
                      style={{ backgroundColor: "#044954" }}
                    >
                      <p className="text-white fw-bold">{t("Uploading Files")}</p>
                      {spinCount.map((count) => {
                        return (
                          <div
                            key={count}
                            className="spinner-grow mx-1 text-white"
                            style={{ height: "6px", width: "6px" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* ----- loading area for file uploading end ----- */}
                </div>

                  {/* ----- file upload ------ */}
                  {fileIsBig ? (
                    <div className="activity_tagsRow">
                      <h6 className="text-white bg-danger">
                        * {t("Uploaded File Size must be lower than 20 mb")}
                      </h6>
                    </div>
                  ) : null}
                  
                  <div className="activity_tagsRow">
                    <h4>{t("File Upload")} (<i>{t(" image / text / pdf / doc / excel / video-mp4,webm,ogv Max: 20MB")}</i>) </h4>

                    <div className="activity_tagsRow row col-12">
                    {/* ----- Map the uploaded files for upload with comment   ----- */}
                    {media.map((files, index) => {
                      return (
                        <div key={index} className="col-4 mb-4">
                          {/* <button
                                type="button"
                                className="close close-btn"
                                aria-label="Close"
                                onClick={() => closeFileHandler(index)}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button> */}
                          {files.type.startsWith("video/") ? (
                            <video controls width="150" height="150">
                              <source src={URL.createObjectURL(files)} type={files.type} />
                              Your browser does not support the video tag.
                            </video>
                          ) : null}

                          {files.type == "image/jpeg" || files.type == "image/png" ? (
                            <img
                              src={URL.createObjectURL(files)}
                              alt="ImageFile"
                              className="rounded"
                              height="150"
                              width="50"
                            />
                          ) : null}
                          {files.type == "text/plain" ||
                          files.type ==
                            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                            <img
                              src={DOCS}
                              alt="docs"
                              className="rounded"
                              height="150"
                              width="50"
                            />
                          ) : null}
                          {files.type ==
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                          files.type == "application/msword" ? (
                            <img
                              src={DOCS}
                              alt="docs"
                              className="rounded"
                              height="150"
                              width="50"
                            />
                          ) : null}
                          {files.type == "application/pdf" ? (
                            <img
                              src={PDF}
                              alt="docs"
                              className="rounded"
                              height="150"
                              width="50"
                            />
                          ) : null}
                          {files.type ==
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                          files.type == "application/vnd.ms-excel" ? (
                            <img
                              src={XLS}
                              alt="docs"
                              className="rounded"
                              height="150"
                              width="50"
                            />
                          ) : null}

                          


                          <p className="text-center">
                            <span>{files.name.slice(0, 15)}</span>
                          </p>
                        </div>
                      );
                    })}
                  </div>


                    <div className="reply_fill">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile02"
                        onChange={multipleFileUploadHandler}
                        multiple
                      />
                    </div>
                  </div>


                  <div className="form_commnts_area">
                    {/* <textarea
                      className="form-control"
                      placeholder={t("Write your idea here")}
                      value={commentMessage}
                      onChange={(e) => setCommentMessage(e.target.value)}
                    ></textarea> */}

                    <CKEditor
                      className="form-control"
                      editor={ClassicEditor}
                      config={editorConfig}
                      data={commentMessage ? commentMessage : ""}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "100px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        let editContent = editor.getData();
                        setCommentMessage(editContent);
                      }}
                    />

                    <div className="furmcmnt_btn">
                      <button
                        className="cmnt_btn"
                        onClick={answerToQuestion}
                        // disabled={isCommentPosting ? true : false}
                        // style={{
                        //   cursor: isCommentPosting ? "not-allowed" : "pointer",
                        // }}
                      >
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>

                  
                  







                </div>
              </div>
              {/* ----- post reply box end ---- */}

              {/* ----- post comments start ---- */}
              {detailsData.comments == undefined ? null : (
                <>
                  {detailsData.comments.records.map((comment, index) => {
                    return (
                      <div className="activity_rightItem" key={index}>
                        <div className="chat_likeBx">

                          {/* comment message box */}
                          <div className="activity_para">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: comment.message,
                              }}
                              className="activity_para"
                            ></div>
                          </div>
                          
                          {/* uploaded files if any in comment */}
                          {comment.media == null ? null : (
                            <div className="d-flex mt-2">
                              {comment.media.map((item, index) => {
                                return (
                                  <div
                                    className="post-media"
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    onClick={() => window.open(item, "_blank")}
                                  >
                                    {item.split(".").pop() == "jpg" ||
                                    item.split(".").pop() == "JPG" ||
                                    item.split(".").pop() == "png" ? (
                                      <img
                                        src={item}
                                        alt="img"
                                        className="rounded"
                                        height="50"
                                        width="50"
                                      />
                                    ) : (
                                      <div>
                                        {item.split(".").pop() == "pdf" ? (
                                          <img
                                            src={PDF}
                                            alt="docs"
                                            className="rounded"
                                            height="50"
                                            width="50"
                                          />
                                        ) : (
                                          <div>
                                            {item.split(".").pop() == "xlsx" ||
                                            item.split(".").pop() == "xls" ? (
                                              <img
                                                src={XLS}
                                                alt="docs"
                                                className="rounded"
                                                height="50"
                                                width="50"
                                              />
                                            ) : (
                                              <div>
                                                {item.split(".").pop() == "docx" ||
                                                item.split(".").pop() == "txt" ||
                                                item.split(".").pop() == "doc" ? (
                                                  <img
                                                    src={DOCS}
                                                    alt="docs"
                                                    className="rounded"
                                                    height="50"
                                                    width="50"
                                                  />
                                                ) : 
                                                
                                                 (<div>
                                                  {item.split(".").pop() == "mp4" ||
                                                  item.split(".").pop() == "webm" ||
                                                  item.split(".").pop() == "ogv" ? (
                                                    <video controls width="150" height="150">
                                                      <source src={item} type={`video/${item.split(".").pop()}`} />
                                                      Your browser does not support the video tag.
                                                    </video>
                                                  ) : null }              
                                                  </div>
                                                 )
                                                
                                                
                                                }
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {/* comment sender details */}
                          <div className="likecmnts_pnl">
                            <div className="cmnts_usr">
                              <img
                                src={
                                  comment.senderimage
                                    ? comment.senderimage
                                    : defaultUser
                                }
                                alt=""
                              />
                            </div>
                            <div className="cmnts_usertext">
                              <h3>{comment.sender}</h3>
                              <div className="form_share">
                                <Link
                                  to="#"
                                  role="button"
                                  onClick={() => likeCommentHandler(comment.id)}
                                >
                                  <i className="material-icons-outlined">
                                    thumb_up
                                  </i>
                                  <em>{t("Likes")}</em>
                                  <span>{comment.likes}</span>
                                </Link>


                                <Link
                                  to="#"
                                  role="button"
                                  onClick={() => showReplyBox(index)}
                                >
                                  <i className="material-icons-outlined">
                                    chat
                                  </i>
                                  <em>{t("Comment")}</em>
                                  <span>{comment.reply.count}</span>
                                </Link>


                                {detailsData.moderator.id == loggedInUserID ||
                                comment.senderid == loggedInUserID ||
                                userInfo.isadmin ? (
                                  <Link
                                    role="button"
                                    to="#"
                                    onClick={() => deleteComment(comment)}
                                  >
                                    <i className="material-icons-outlined">
                                      delete
                                    </i>
                                    <em>{t("Remove")}</em>
                                  </Link>
                                ) : null}

                                {/* ------ Winner icon displayed here --------- */}
                                {detailsData.moderator.id == loggedInUserID ? (
                                  <Link
                                    to="#"
                                    className={
                                      comment.winner != true ||
                                      comment.winner == null
                                        ? "nowinner"
                                        : "activewinner"
                                    }
                                    onClick={() => {
                                      championHandler(comment);
                                    }}
                                  >
                                    <i className="material-icons-round">
                                      emoji_events
                                    </i>
                                  </Link>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        {replyBoxIndex == index ? (
                          <div className="activity_comntsRow">
                            <div className="input_fill">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Reply to comment")}
                                name="replyMessage"
                                value={replyMessage}
                                onChange={(e) =>
                                  setReplyMessage(e.target.value)
                                }
                              />
                            </div>

                            <button
                              className="cmnt_btn"
                              onClick={() => replyToAnswerHandler(comment)}
                              disabled={isReplyPosting ? true : false}
                              style={{
                                cursor: isReplyPosting
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            >
                              <span className="material-icons-outlined">
                                send
                              </span>
                            </button>
                          </div>
                        ) : null}


                        {replyWarning && (
                          <p className="text-danger">
                            * {t("Please leave a reply")}
                          </p>
                        )}

                        {/* ----- post reply of comments start ---- */}
                        {comment.reply.records.map((subcomment, index2) => {
                          return (
                            <div className="reply_qutation_inr" key={index2}>
                              <h6 className="mb-2 mt-4">{subcomment.created}</h6>
                              <div className="quote_replybx bg-white p-3 rounded-5">

                                  {/* replied which is quoted */}

                                  {subcomment.replyto.message ? (
                                  <div className="activity_usrPnl mb-2">
                                      <i className="material-icons-outlined">reply</i>
                                      <div className="user_activty">
                                          
                                          <img
                                            src={
                                              subcomment.replyto.senderimg
                                                ? subcomment.replyto.senderimg
                                                : defaultUser
                                            }
                                            alt=""
                                            className="mCS_img_loaded"
                                          />
                                      </div>
                                      <div className="activty_usrname">
                                          <h4 className="text-muted">{subcomment.replyto?.sender??''}</h4>
                                      </div>
                                  </div>
                                  ):''}

                                  {subcomment.replyto.message ? (
                                  <p className="mb-2 text-muted">
                                    {subcomment.replyto?.message??''}
                                  </p>
                                  ):''}
                                  
                                  {/* message as replied */}
                                  <div className="quote_msg p-3 rouded-5 mb-3">
                                      <p>{subcomment.message}</p>
                                  </div>

                                  {/* Details who replied and action button to like, reply more, remove */}
                                  <div className="activity_usrPnl reply_usr_row align-items-start">
                                      <div className="user_activty">
                                          <img src={
                                          subcomment.senderimage
                                          ? subcomment.senderimage
                                          : defaultUser
                                          } 
                                          alt="" 
                                          className="img-fluid" />
                                      </div>
                                      <div className="activty_usrname" style={{ width: '100%' }}>
                                          <h4 className="fw-bold mb-2">{subcomment.sender}</h4>
                                          <div className="form_share">

                                              <div className="chat_likeBx">
                                                {/* like ------------- */}
                                                <Link
                                                  to="#"
                                                  role="button"
                                                  onClick={() => likeCommentHandler(subcomment.id)}
                                                >
                                                  <i className="material-icons-outlined">
                                                    thumb_up
                                                  </i>
                                                  <em>{t("Likes")}</em>
                                                  <span>{subcomment.likes}</span>
                                                </Link>

                                                {/* Comment ---------------- */}
                                                <Link
                                                  to="#"
                                                  role="button"
                                                  onClick={() => showReplyBox(subcomment.id)}
                                                >
                                                  <i className="material-icons-outlined">
                                                    chat
                                                  </i>
                                                  <em>{t("Reply")}</em>
                                                  <span>{subcomment.replyto_oncomment}</span>
                                                </Link>

                                                {/*  Remove ------------------- */}
                                                {detailsData.moderator.id == loggedInUserID ||
                                                subcomment.senderid == loggedInUserID ||
                                                userInfo.isadmin ? (
                                                  <Link
                                                    role="button"
                                                    to="#"
                                                    onClick={() => deleteComment(subcomment)}
                                                  >
                                                    <i className="material-icons-outlined">
                                                      delete
                                                    </i>
                                                    <em>{t("Remove")}</em>
                                                  </Link>
                                                ) : null}


                                              </div>

                                          </div>

                                          {replyBoxIndex == subcomment.id ? (

                                          <div className="activity_comntsRow">
                                            <div className="input_fill">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t("Reply to comment")}
                                                name="replyMessage"
                                                value={replyMessage}
                                                onChange={(e) =>
                                                  setReplyMessage(e.target.value)
                                                }
                                              />
                                            </div>

                                            <button
                                              className="cmnt_btn"
                                              onClick={() => replyToAnswerHandler(comment, subcomment)}
                                              disabled={isReplyPosting ? true : false}
                                              style={{
                                                cursor: isReplyPosting
                                                  ? "not-allowed"
                                                  : "pointer",
                                              }}
                                            >
                                              <span className="material-icons-outlined">
                                                send
                                              </span>
                                            </button>
                                          </div>
                                          ):null}

                                      </div>
                                  </div>

                              </div>
                          </div>
                          );
                        })}
                        {/* ----- post reply of comments end ---- */}
                      </div>
                    );
                  })}
                </>
              )}
              {/* ----- post comments end ---- */}
            </div>
          )}
          {/* ---- main activity details start end ----- */}
        </div>
      )}
      <AddTagModalRightPanel
        afterModalClose={afterModalClose}
        postId={postId}
      />
    </>
  );
};

export default ActivityRightpanel;
